import type { Ref } from 'vue';

type ChangePasswordPopupType = {
  isOpen: Ref<boolean>
  openPasswordPopup: () => void;
  closePasswordPopup: () => void;
}

export const changePasswordPopupStore = defineStore('changePasswordPopup', (): ChangePasswordPopupType => {
  const isOpen = ref<boolean>(false);
  const openPasswordPopup = () => {
    isOpen.value = true;
  };
  const closePasswordPopup = () => {
    isOpen.value = false;
  }

  return {
    isOpen,
    openPasswordPopup,
    closePasswordPopup
  };
});
