import { default as indexNJPAXYODrOMeta } from "/var/www/smscode-frontend/pages/authorization/index.vue?macro=true";
import { default as indexWgSMQ67H0oMeta } from "/var/www/smscode-frontend/pages/cabinet/api/index.vue?macro=true";
import { default as indexnZl3WlLrWBMeta } from "/var/www/smscode-frontend/pages/cabinet/index.vue?macro=true";
import { default as indexO63nW0SJV5Meta } from "/var/www/smscode-frontend/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as indexs5jcX6XIdqMeta } from "/var/www/smscode-frontend/pages/cabinet/payments/index.vue?macro=true";
import { default as indexfyQER083JyMeta } from "/var/www/smscode-frontend/pages/cabinet/profile/index.vue?macro=true";
import { default as indexOzwKi5aeLbMeta } from "/var/www/smscode-frontend/pages/forgot-password/index.vue?macro=true";
import { default as indexLoQ2G27ixjMeta } from "/var/www/smscode-frontend/pages/index.vue?macro=true";
import { default as indexQmNvPfBqpyMeta } from "/var/www/smscode-frontend/pages/payment/result/index.vue?macro=true";
import { default as indexeHzS5F1pBFMeta } from "/var/www/smscode-frontend/pages/registration/index.vue?macro=true";
export default [
  {
    name: indexNJPAXYODrOMeta?.name ?? "authorization___en___default",
    path: indexNJPAXYODrOMeta?.path ?? "/authorization",
    meta: indexNJPAXYODrOMeta || {},
    alias: indexNJPAXYODrOMeta?.alias || [],
    redirect: indexNJPAXYODrOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJPAXYODrOMeta?.name ?? "authorization___en",
    path: indexNJPAXYODrOMeta?.path ?? "/en/authorization",
    meta: indexNJPAXYODrOMeta || {},
    alias: indexNJPAXYODrOMeta?.alias || [],
    redirect: indexNJPAXYODrOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexNJPAXYODrOMeta?.name ?? "authorization___ru",
    path: indexNJPAXYODrOMeta?.path ?? "/ru/authorization",
    meta: indexNJPAXYODrOMeta || {},
    alias: indexNJPAXYODrOMeta?.alias || [],
    redirect: indexNJPAXYODrOMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexWgSMQ67H0oMeta?.name ?? "cabinet-api___en___default",
    path: indexWgSMQ67H0oMeta?.path ?? "/cabinet/api",
    meta: indexWgSMQ67H0oMeta || {},
    alias: indexWgSMQ67H0oMeta?.alias || [],
    redirect: indexWgSMQ67H0oMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexWgSMQ67H0oMeta?.name ?? "cabinet-api___en",
    path: indexWgSMQ67H0oMeta?.path ?? "/en/cabinet/api",
    meta: indexWgSMQ67H0oMeta || {},
    alias: indexWgSMQ67H0oMeta?.alias || [],
    redirect: indexWgSMQ67H0oMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexWgSMQ67H0oMeta?.name ?? "cabinet-api___ru",
    path: indexWgSMQ67H0oMeta?.path ?? "/ru/cabinet/api",
    meta: indexWgSMQ67H0oMeta || {},
    alias: indexWgSMQ67H0oMeta?.alias || [],
    redirect: indexWgSMQ67H0oMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexnZl3WlLrWBMeta?.name ?? "cabinet___en___default",
    path: indexnZl3WlLrWBMeta?.path ?? "/cabinet",
    meta: indexnZl3WlLrWBMeta || {},
    alias: indexnZl3WlLrWBMeta?.alias || [],
    redirect: indexnZl3WlLrWBMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexnZl3WlLrWBMeta?.name ?? "cabinet___en",
    path: indexnZl3WlLrWBMeta?.path ?? "/en/cabinet",
    meta: indexnZl3WlLrWBMeta || {},
    alias: indexnZl3WlLrWBMeta?.alias || [],
    redirect: indexnZl3WlLrWBMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexnZl3WlLrWBMeta?.name ?? "cabinet___ru",
    path: indexnZl3WlLrWBMeta?.path ?? "/ru/cabinet",
    meta: indexnZl3WlLrWBMeta || {},
    alias: indexnZl3WlLrWBMeta?.alias || [],
    redirect: indexnZl3WlLrWBMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexO63nW0SJV5Meta?.name ?? "cabinet-numbers-history___en___default",
    path: indexO63nW0SJV5Meta?.path ?? "/cabinet/numbers-history",
    meta: indexO63nW0SJV5Meta || {},
    alias: indexO63nW0SJV5Meta?.alias || [],
    redirect: indexO63nW0SJV5Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexO63nW0SJV5Meta?.name ?? "cabinet-numbers-history___en",
    path: indexO63nW0SJV5Meta?.path ?? "/en/cabinet/numbers-history",
    meta: indexO63nW0SJV5Meta || {},
    alias: indexO63nW0SJV5Meta?.alias || [],
    redirect: indexO63nW0SJV5Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexO63nW0SJV5Meta?.name ?? "cabinet-numbers-history___ru",
    path: indexO63nW0SJV5Meta?.path ?? "/ru/cabinet/numbers-history",
    meta: indexO63nW0SJV5Meta || {},
    alias: indexO63nW0SJV5Meta?.alias || [],
    redirect: indexO63nW0SJV5Meta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexs5jcX6XIdqMeta?.name ?? "cabinet-payments___en___default",
    path: indexs5jcX6XIdqMeta?.path ?? "/cabinet/payments",
    meta: indexs5jcX6XIdqMeta || {},
    alias: indexs5jcX6XIdqMeta?.alias || [],
    redirect: indexs5jcX6XIdqMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexs5jcX6XIdqMeta?.name ?? "cabinet-payments___en",
    path: indexs5jcX6XIdqMeta?.path ?? "/en/cabinet/payments",
    meta: indexs5jcX6XIdqMeta || {},
    alias: indexs5jcX6XIdqMeta?.alias || [],
    redirect: indexs5jcX6XIdqMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexs5jcX6XIdqMeta?.name ?? "cabinet-payments___ru",
    path: indexs5jcX6XIdqMeta?.path ?? "/ru/cabinet/payments",
    meta: indexs5jcX6XIdqMeta || {},
    alias: indexs5jcX6XIdqMeta?.alias || [],
    redirect: indexs5jcX6XIdqMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexfyQER083JyMeta?.name ?? "cabinet-profile___en___default",
    path: indexfyQER083JyMeta?.path ?? "/cabinet/profile",
    meta: indexfyQER083JyMeta || {},
    alias: indexfyQER083JyMeta?.alias || [],
    redirect: indexfyQER083JyMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexfyQER083JyMeta?.name ?? "cabinet-profile___en",
    path: indexfyQER083JyMeta?.path ?? "/en/cabinet/profile",
    meta: indexfyQER083JyMeta || {},
    alias: indexfyQER083JyMeta?.alias || [],
    redirect: indexfyQER083JyMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexfyQER083JyMeta?.name ?? "cabinet-profile___ru",
    path: indexfyQER083JyMeta?.path ?? "/ru/cabinet/profile",
    meta: indexfyQER083JyMeta || {},
    alias: indexfyQER083JyMeta?.alias || [],
    redirect: indexfyQER083JyMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexOzwKi5aeLbMeta?.name ?? "forgot-password___en___default",
    path: indexOzwKi5aeLbMeta?.path ?? "/forgot-password",
    meta: indexOzwKi5aeLbMeta || {},
    alias: indexOzwKi5aeLbMeta?.alias || [],
    redirect: indexOzwKi5aeLbMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexOzwKi5aeLbMeta?.name ?? "forgot-password___en",
    path: indexOzwKi5aeLbMeta?.path ?? "/en/forgot-password",
    meta: indexOzwKi5aeLbMeta || {},
    alias: indexOzwKi5aeLbMeta?.alias || [],
    redirect: indexOzwKi5aeLbMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexOzwKi5aeLbMeta?.name ?? "forgot-password___ru",
    path: indexOzwKi5aeLbMeta?.path ?? "/ru/forgot-password",
    meta: indexOzwKi5aeLbMeta || {},
    alias: indexOzwKi5aeLbMeta?.alias || [],
    redirect: indexOzwKi5aeLbMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexLoQ2G27ixjMeta?.name ?? "index___en___default",
    path: indexLoQ2G27ixjMeta?.path ?? "/",
    meta: indexLoQ2G27ixjMeta || {},
    alias: indexLoQ2G27ixjMeta?.alias || [],
    redirect: indexLoQ2G27ixjMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexLoQ2G27ixjMeta?.name ?? "index___en",
    path: indexLoQ2G27ixjMeta?.path ?? "/en",
    meta: indexLoQ2G27ixjMeta || {},
    alias: indexLoQ2G27ixjMeta?.alias || [],
    redirect: indexLoQ2G27ixjMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexLoQ2G27ixjMeta?.name ?? "index___ru",
    path: indexLoQ2G27ixjMeta?.path ?? "/ru",
    meta: indexLoQ2G27ixjMeta || {},
    alias: indexLoQ2G27ixjMeta?.alias || [],
    redirect: indexLoQ2G27ixjMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmNvPfBqpyMeta?.name ?? "payment-result___en___default",
    path: indexQmNvPfBqpyMeta?.path ?? "/payment/result",
    meta: indexQmNvPfBqpyMeta || {},
    alias: indexQmNvPfBqpyMeta?.alias || [],
    redirect: indexQmNvPfBqpyMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmNvPfBqpyMeta?.name ?? "payment-result___en",
    path: indexQmNvPfBqpyMeta?.path ?? "/en/payment/result",
    meta: indexQmNvPfBqpyMeta || {},
    alias: indexQmNvPfBqpyMeta?.alias || [],
    redirect: indexQmNvPfBqpyMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmNvPfBqpyMeta?.name ?? "payment-result___ru",
    path: indexQmNvPfBqpyMeta?.path ?? "/ru/payment/result",
    meta: indexQmNvPfBqpyMeta || {},
    alias: indexQmNvPfBqpyMeta?.alias || [],
    redirect: indexQmNvPfBqpyMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHzS5F1pBFMeta?.name ?? "registration___en___default",
    path: indexeHzS5F1pBFMeta?.path ?? "/registration",
    meta: indexeHzS5F1pBFMeta || {},
    alias: indexeHzS5F1pBFMeta?.alias || [],
    redirect: indexeHzS5F1pBFMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHzS5F1pBFMeta?.name ?? "registration___en",
    path: indexeHzS5F1pBFMeta?.path ?? "/en/registration",
    meta: indexeHzS5F1pBFMeta || {},
    alias: indexeHzS5F1pBFMeta?.alias || [],
    redirect: indexeHzS5F1pBFMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHzS5F1pBFMeta?.name ?? "registration___ru",
    path: indexeHzS5F1pBFMeta?.path ?? "/ru/registration",
    meta: indexeHzS5F1pBFMeta || {},
    alias: indexeHzS5F1pBFMeta?.alias || [],
    redirect: indexeHzS5F1pBFMeta?.redirect,
    component: () => import("/var/www/smscode-frontend/pages/registration/index.vue").then(m => m.default || m)
  }
]