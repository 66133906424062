import revive_payload_client_4sVQNw7RlN from "/var/www/smscode-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/smscode-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/smscode-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/smscode-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/smscode-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/smscode-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/smscode-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/smscode-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_bKSoGl1L7p from "/var/www/smscode-frontend/node_modules/yandex-metrika-module-nuxt3/dist/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/smscode-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_W86eSGFqyZ from "/var/www/smscode-frontend/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/smscode-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import customoOfetch_zBJTuv7VXR from "/var/www/smscode-frontend/plugins/customoOfetch.ts";
import initPlaginClient_client_Nsw0alNzzD from "/var/www/smscode-frontend/plugins/initPlaginClient.client.ts";
import priceFormat_Cb2yhyBrV2 from "/var/www/smscode-frontend/plugins/priceFormat.ts";
import toast_ysMjUcU7eJ from "/var/www/smscode-frontend/plugins/toast.ts";
import vueDatePicker_GfeYHwmpe2 from "/var/www/smscode-frontend/plugins/vueDatePicker.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_bKSoGl1L7p,
  i18n_yfWm7jX06p,
  plugin_client_W86eSGFqyZ,
  chunk_reload_client_UciE0i6zes,
  customoOfetch_zBJTuv7VXR,
  initPlaginClient_client_Nsw0alNzzD,
  priceFormat_Cb2yhyBrV2,
  toast_ysMjUcU7eJ,
  vueDatePicker_GfeYHwmpe2
]