import type { Ref } from 'vue';

type ApiKeyPopupType = {
  isOpen: Ref<boolean>
  openPopup: () => void;
  closePopup: () => void;
}

export const changeApiKeyPopupStore = defineStore('apiKeyPopup', (): ApiKeyPopupType => {
  const isOpen = ref<boolean>(false);
  const openPopup = () => {
    isOpen.value = true;
  };
  const closePopup = () => {
    isOpen.value = false;
  }

  return {
    isOpen,
    openPopup,
    closePopup
  };
});
