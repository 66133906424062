import { h, render } from 'vue';
import ToastComponent from '~/components/widgets/toast/Toast.vue';
import { defineNuxtPlugin } from '#imports';

export type IToastTypes = 'success' | 'info' | 'error' | 'warning' | 'default' | 'pop-up';
export type IToastPosition = 'top-right' | 'top' | 'top-left' | 'bottom-right' | 'bottom' | 'bottom-left' | 'center';
type IMessage = string;

export interface IActiveToast {
  dismiss (): void;
}

export interface IOptions {
  message?: string | undefined,
  type?: IToastTypes,
  position?: IToastPosition,
  duration?: number,
}

export interface IToast {
  open (options: IOptions): IActiveToast;

  success (message: IMessage, options?: IOptions): IActiveToast;

  error (message: IMessage, options?: IOptions): IActiveToast;

  warning (message: IMessage, options?: IOptions): IActiveToast;

  info (message: IMessage, options?: IOptions): IActiveToast;

  default (message: IMessage, options?: IOptions): IActiveToast;

  popUp (message: IMessage, options?: IOptions): IActiveToast;
}

const defaultProps = {
  position: 'top-right'
};

function createComponent (component: any, props: any, parentContainer: any, slots: any = {}) {
  const vNode = h(component, props, slots);
  const container = document.createElement('div');
  container.classList.add('v-toast');
  parentContainer.appendChild(container);
  render(vNode, container);

  return vNode.component;
}

export function removeElement (el: Element | ShadowRoot | null) {
  if (el && el instanceof Element) {
    typeof el.remove !== 'undefined' ? el.remove() : el.parentNode?.removeChild(el);
  }
}

export default defineNuxtPlugin({
  name: 'toast',
  setup (nuxtApp) {
    const onOpen = function (options: IOptions) {
      const propsData = { ...defaultProps, ...options };
      createComponent(ToastComponent, options, document.querySelector('.custom-toast-container'));
    };

    const onSuccess = function (message: IMessage, options?: IOptions) {
      return onOpen({
        ...options,
        message: message || options?.message,
        type: 'success'
      });
    };

    const onError = function (message: IMessage, options?: IOptions) {
      return onOpen({
        ...options,
        message: message || options?.message,
        type: 'error'
      });
    };

    const onWarning = function (message: IMessage, options?: IOptions) {
      return onOpen({
        ...options,
        message: message || options?.message,
        type: 'warning'
      });
    };

    const onInfo = function (message: IMessage, options?: IOptions) {
      return onOpen({
        ...options,
        message: message || options?.message,
        type: 'info'
      });
    };

    const onDefault = function (message: IMessage, options?: IOptions) {
      return onOpen({
        ...options,
        message: message || options?.message,
        type: 'default'
      });
    };

    const onPopUp = function (message: IMessage, options?: IOptions) {
      return onOpen({
        ...options,
        message: message || options?.message,
        type: 'pop-up'
      });
    };

    return {
      provide: {
        toast: ({
          open: onOpen,
          success: onSuccess,
          error: onError,
          warning: onWarning,
          info: onInfo,
          default: onDefault,
          popUp: onPopUp
        })
      }
    };
  }

});
