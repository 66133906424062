import { useUserAuth } from '~/composables/user/useUserAuth';

// тут можно загружать и подключать все что нужно инициализировать на стророе клиента при старте
export default defineNuxtPlugin(async (nuxtApp) => {
  const {
    isLoggedIn,
    getUserMe
  } = useUserAuth();

  if (!isLoggedIn.value) {
    await getUserMe().catch((err) => {
      console.log('load user plugin:', err.response?._data?.message);
    });
  }
});
