import { defineNuxtPlugin } from '#imports';

export interface INumberFormat {
  amount: number | undefined;
  decimalCount?: number;
  decimal?: string;
  thousands?: string;
}

export default defineNuxtPlugin({
  name: 'priceFormat',
  setup () {
    const priceFormat = ({
      amount = 0,
      decimalCount = 0,
      decimal = '.',
      thousands = ' '
    }: INumberFormat): string => {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      const i = parseInt(String(amount = Number(Math.abs(Number(amount) || 0).toFixed(decimalCount)))).toString();
      const j = (i.length > 3) ? i.length % 3 : 0;

      const decimalValue = decimal + Math.abs(amount - Number(i)).toFixed(decimalCount).slice(2);

      return negativeSign + (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
        (decimalCount ? (Number(decimalValue) === 0 ? '' : decimalValue) : '');
    };

    return {
      provide: {
        priceFormat: (option: INumberFormat) => priceFormat(option)
      }
    };
  }
});
