<template>
  <div class="content">
    <TheDesktopHeader v-if="!$viewport.isLessThan('md')" />
    <TheMobileHeader v-if="$viewport.isLessThan('md')" />
  </div>
</template>

<script lang="ts" setup>
const TheDesktopHeader = defineAsyncComponent(() => import('~/components/header/TheDesktopHeader.vue'));
const TheMobileHeader = defineAsyncComponent(() => import('~/components/header/TheMobileHeader.vue'));
const $viewport = useViewport();
</script>

<style scoped lang="scss">
</style>
