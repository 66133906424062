import type { Ref } from 'vue';

type SuccessPopupType = {
  isOpen: Ref<boolean>;
  // title?: Ref<string>;
  // message?: Ref<string>;
  openSuccessPopup: () => void;
  closeSuccessPopup: () => void;
}

export const successPopupStore = defineStore('successPopup', (): SuccessPopupType => {
  const isOpen = ref<boolean>(false);
  const openSuccessPopup = () => {
    isOpen.value = true;
  };
  const closeSuccessPopup = () => {
    isOpen.value = false;
  }

  return {
    isOpen,
    openSuccessPopup,
    closeSuccessPopup
  };
});
