export default defineNuxtPlugin({
  name: 'customFetch',
  setup (_nuxtapp) {
    const cookiesHeaders = useRequestHeaders(['cookie']);

    const headers: { [key: string]: string } = {
      Accept: 'application/json',
      // Authorization: 'Basic YWRtaW46UTFXMkUzUjRUNQ==',
      ...cookiesHeaders
    };

    const ofetchInstanse = $fetch.create({
      baseURL: '/api-proxy/',
      headers: { ...headers },
      onRequest ({
        request,
        options,
        error
      }) {
        // if (userAuth.value) {
        //
        //   options.headers = options.headers || {}
        //   options.headers.Authorization = `Bearer ${userAuth.value}`
        // }
      },
      onResponse ({
        response,
        error
      }) {
        // response._data = new myBusinessResponse(response._data)
      },
      onResponseError ({ response }) {
        // if (response.status === 401) {
        //   return navigateTo('/login')
        // }
      }

    });

    return {
      provide: {
        api: ofetchInstanse
      }
    };
  }
});
