<template>
  <div
    ref="root"
    class="toast-root"
  >
    <div
      class="toast-component"
      :class="classListComponent"
    >
      <div
        class="toast-component__content"
        :class="classListContent"
      >
        <div
          v-if="toastIcon"
          class="toast-component__message-icon"
        >
          <img :src="toastIcon" alt="notification-icon"/>
        </div>
        <div
            class="toast-component__message"
            v-html="message"
        />
        <div
            @click="closeAllToast"
            class="toast-component__close-icon"
        >
          <img src="~/assets/img/toast/close-icon.svg" alt="cloe-icon"/>
        </div>
      </div>
    </div>
    <div
        v-if="classListComponent==='center'"
        class="toast-overlay"

        @click="closeAllToast"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, render } from 'vue';
import { removeElement } from '~/plugins/toast';

import type { IToastPosition, IToastTypes } from '~/plugins/toast';

const {
  message = '',
  position = 'top-right',
  type = 'success',
  duration = 3000
} = defineProps<{
	message: string;
	type: IToastTypes;
	position?: IToastPosition;
	duration?: number
}>();

const classListComponent = computed(() => [position].filter((t) => !!t).join(' '));
const classListContent = computed(() => [type].filter((t) => !!t).join(' '));
const root = ref<Element | ShadowRoot | null>(null);
const openToast = () => {
  setTimeout(() => {
    //closeAllToast();
  }, duration);
};

const closeAllToast = () => {
  if (root.value !== null) {
    render(null, root.value);
    removeElement(root.value);
  }
};

const toastIcon = computed(() => {
  if (type === 'success') {
    return useAsset('img/toast/success-icon.svg');
  }
  if (type === 'info') {
    return useAsset('img/toast/info-icon.svg');
  }
  if (type === 'error') {
    return useAsset('img/toast/error-icon.svg');
  }
  return
})

onMounted(() => {
  openToast();
});

</script>

<style lang="scss">
.custom-toast-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10000;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.toast-root {
	position: relative;
}

.toast-component {
	//position: fixed;
	z-index: var(--toast-main-z-index);

	&.center {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	&.top {
		top: 20px;
		left: 50%;
		transform: translateX(-50px);
	}

	&.top-right {
		top: 20px;
		right: 20px;
	}

	&.top-left {
		top: 20px;
		left: 20px;
	}

	&.bottom {
		bottom: 20px;
		left: 50%;
		transform: translateX(-50px);
	}

	&.bottom-right {
		bottom: 20px;
		right: 20px;
	}

	&.bottom-left {
		bottom: 20px;
		left: 20px;
	}
}

.toast-overlay {
	inset: 0;
	background: var(--toast-overlay-bg);
	position: fixed;
	z-index: var(--toast-overlay-z-index);
}

.toast-component__content {
	&.success {
		background-color: var(--toast-success-bg);
		color: var(--toast-success-text);
	}

	&.info {
		background-color: var(--toast-info-bg);
		color: var(--toast-info-text);
	}

	&.error {
		background-color: var(--toast-error-bg);
		color: var(--toast-error-text);
	}

	&.warning {
		background-color: var(--toast-warning-bg);
		color: var(--toast-warning-text);
	}

	&.default {
		background-color: var(--toast-info-bg);
		color: var(--toast-info-text);
	}

	&.pop-up {
	}
}

.toast-component__content {
  display: flex;
  align-items: center;
  gap: 26px;
	padding: 16px 16px 16px 31px;
	box-sizing: border-box;
	width: 100vw;
	max-width: 320px;
	box-shadow: 0 2px 26px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
}

.toast-component__close-icon {
  position: absolute;
  top: 9px;
  right: 8px;
  cursor: pointer;
}

@keyframes up {
	0% {
		transform: translate(-50%, -50%);
	}

	95% {
		transform: translateY(999px);
	}
}
</style>
